






















































































































































































































































































































































































import { SearchBuilder } from "@/builder";
import {
  useBankTransfer,
  useBlob,
  useCurrency,
  useDisabledFromTomorrow,
} from "@/hooks";
import MNotificationVue from "@/mixins/MNotification.vue";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import { ALLOWED_FILE_SIZE } from "@/models/constants/upload.constant";
import { Mode } from "@/models/enums/global.enum";
import { Messages } from "@/models/enums/messages.enum";
import { CreateBankTransfer as PostBankTransferDTO } from "@/models/interface/cashManagement.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { DataWarehouseBranch } from "@/models/interface/logistic.interface";
import { DataMasterCurrency } from "@/models/interface/settings.interface";
import { cashManagementServices } from "@/services/cashmanagement.service";
import { logisticServices } from "@/services/logistic.service";
import { masterServices } from "@/services/master.service";
import { settingsServices } from "@/services/settings.service";
import {
  currencyFormat,
  formatterNumber,
  reverseFormatNumber,
} from "@/validator/globalvalidator";
import { WrappedFormUtils } from "ant-design-vue/types/form/form";
import { UploadFile } from "ant-design-vue/types/upload";
import moment from "moment";
import printJS from "print-js";
import { Component, Mixins } from "vue-property-decorator";
import { mapState } from "vuex";

enum STATUSDATA {
  DRAFT = "DRAFT",
  CANCELLED = "CANCELLED",
  NEED_APPROVAL = "NEED_APPROVAL",
  APPROVED = "APPROVED",
  SATTLED = "SATTLED",
}

@Component({
  computed: {
    ...mapState({
      storeBaseDecimalPlace: (st: any) =>
        st.preferenceStore.baseDecimalPlace as number,
    }),
  },
})
export default class CreateBankTransfer extends Mixins(MNotificationVue) {
  formatterNumber = formatterNumber;
  reverseFormatNumber = reverseFormatNumber;
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
  useDisabledFromTomorrow = useDisabledFromTomorrow;

  limit = 10;
  page = 0;
  statusData = "";
  form!: WrappedFormUtils;
  disabled = {
    rate: false,
    branch: false,
    currency: false,
    transferDate: false,
    transferAmount: false,
    description: false,
    file: false,
    bankSource: false,
    bankDestination: false,
  };
  show = {
    reject: false,
    approve: false,

    saveAsDraft: false,
    submit: false,
    update: false,
  };
  fileList: UploadFile[] = [];
  formRules = {
    transferDate: {
      decorator: [
        "transferDate",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    branch: {
      decorator: [
        "branch",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    transferNo: {
      decorator: ["transferNo"],
    },
    status: {
      decorator: ["status"],
    },
    bankSource: {
      decorator: [
        "bankSource",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    bankDestination: {
      decorator: [
        "bankDestination",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    currency: {
      decorator: [
        "currency",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    rate: {
      decorator: [
        "rate",
        {
          type: "number",
        },
      ],
    },
    transferAmount: {
      decorator: [
        "transferAmount",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    description: {
      decorator: [
        "description",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    bankAccountSource: {
      decorator: ["bankAccountSource"],
    },
    bankAccountDestination: {
      decorator: ["bankAccountDestination"],
    },
    bankNameSource: {
      decorator: ["bankNameSource"],
    },
    bankNameDestination: {
      decorator: ["bankNameDestination"],
    },
    bankAccountNumberSource: {
      decorator: ["bankAccountNumberSource"],
    },
    bankAccountNumberDestination: {
      decorator: ["bankAccountNumberDestination"],
    },
    bankBranchNameSource: {
      decorator: ["bankBranchNameSource"],
    },
    bankBranchNameDestination: {
      decorator: ["bankBranchNameDestination"],
    },
  };
  dataBankCurrencySource = "";
  dataBranch = [] as DataWarehouseBranch[];
  dataBankSource = [] as any[];
  dataBankDestination = [] as any[];
  dataCurrency = [] as DataMasterCurrency[];
  loading = {
    branch: false,
    table: false,
    currency: false,
    bankSource: false,
    bankDestination: false,
    reject: false,
    approve: false,
    saveAsDraft: false,
    submit: false,
    update: false,
    print: false,
  };
  mode = "";
  journalNumber = {
    id: "",
    name: "",
  };
  urlForSend = "";
  docId = "";

  beforeCreate(): void {
    this.form = this.$form.createForm(this, { name: "bankTransfer" });
  }

  created(): void {
    this.mode = this.$route.meta.mode;
    this.docId = this.$route.params.id;
  }

  mounted(): void {
    this.getListBankDestination("");
    this.getBranch("");
    this.getCurrency("");
    if (this.mode === Mode.CREATE) {
      this.show.submit = true;
      this.show.saveAsDraft = true;
    } else {
      this.getDetail();
    }
  }

  changeDropdown(
    value: "",
    type: "currency" | "bankSource" | "bankDestination"
  ): void {
    if (type === "bankSource") {
      this.form.setFieldsValue({
        bankAccountSource: this.dataBankSource.find(item => item.id == value)
          ?.bankAccName,
        bankAccountNumberSource: this.dataBankSource.find(
          item => item.id == value
        )?.bankAccNumber,
        bankNameSource: this.dataBankSource.find(item => item.id == value)
          ?.bankName,
        bankBranchNameSource: this.dataBankSource.find(item => item.id == value)
          ?.bankBranch,
      });
      if (value) {
        this.dataBankCurrencySource = this.dataBankSource.find(
          item => item.id == value
        ).currency;
      } else this.dataBankCurrencySource = "";
      if (this.dataBankDestination.findIndex(item => item.id == value) != -1) {
        this.dataBankDestination.forEach((item, index) => {
          if (
            index ==
            this.dataBankDestination.findIndex(item => item.id == value)
          ) {
            item.disabled = true;
          } else {
            item.disabled = false;
          }
        });
      } else {
        this.dataBankDestination.forEach(item => {
          item.disabled = false;
        });
      }
    } else if (type === "bankDestination") {
      this.form.setFieldsValue({
        bankAccountDestination:
          this.dataBankDestination.find(item => item.id == value)
            ?.bankAccName ?? "",
        bankAccountNumberDestination:
          this.dataBankDestination.find(item => item.id == value)
            ?.bankAccNumber ?? "",
        bankNameDestination:
          this.dataBankDestination.find(item => item.id == value)?.bankName ??
          "",
        bankBranchNameDestination:
          this.dataBankDestination.find(item => item.id == value)?.bankBranch ??
          "",
      });
    } else if (type === "currency") {
      this.form.setFieldsValue({
        currency: value,
        rate: 1,
        bankSource: "",
        bankAccountSource: "",
        bankAccountNumberSource: "",
        bankNameSource: "",
        bankBranchNameSource: "",
      });
      if (this.form.getFieldValue("currency") !== "IDR") {
        this.generateRate(value);
      }
      this.getListBankSource("");
    }
  }

  generateRate(currencyCode: string): void {
    let params = {
      name: `search=key~*feature_base_currency*`,
    } as RequestQueryParamsModel;
    if (currencyCode === "IDR") return;
    settingsServices.getPreference(params).then(data => {
      params = {
        limit: 1,
        page: 0,
        search: `search=fromCurrency.currencyCode~${currencyCode}_AND_toCurrency.currencyCode~${
          this.dataCurrency.find(item => item.id == data[0].value)?.currencyCode
        }`,
      } as RequestQueryParamsModel;
      settingsServices.listOfCurrency(params, "").then(res => {
        if (res.data.length > 0) {
          this.form.setFieldsValue({
            rate: res.data[0].rate ?? 1,
          });
        } else {
          this.form.setFieldsValue({
            rate: 1,
          });
        }
      });
    });
  }

  back() {
    this.$confirm({
      title: this.$t("lbl_leave_page"),
      onOk: () => {
        this.$router.push("/cash-management/transactions/bank-transfer");
      },
      onCancel() {
        return;
      },
    });
  }

  onCLick(type) {
    this.form.validateFields((err, value) => {
      if (err) {
        this.showNotifError("lbl_validation_required_error");
        return;
      }

      const payload: PostBankTransferDTO = {
        branchId: value.branch,
        transferDate: moment(value.transferDate).format(),
        currencyCode: value.currency,
        rates: value.rate ?? 1,
        transferAmount: value.transferAmount,
        description: value.description,
        attachment: this.urlForSend,
        bankSourceId: value.bankSource,
        bankSourceCurrencyCode: this.dataBankCurrencySource,
        bankDestinationId: value.bankDestination,
      };

      if (this.mode === Mode.CREATE) {
        switch (type) {
          case "submit":
            this.loading.submit = true;
            cashManagementServices
              .createBankTransfer(payload)
              .then(response => {
                this.$router.push(
                  "/cash-management/transactions/bank-transfer"
                );
                this.showNotifSuccess("notif_submit_success", {
                  documentNumber: response.documentNo,
                });
              })

              .finally(() => (this.loading.submit = false));
            break;
          case "saveAsDraft":
            this.loading.saveAsDraft = true;
            cashManagementServices
              .draftBankTransfer(payload)
              .then(response => {
                this.$router.push(
                  "/cash-management/transactions/bank-transfer"
                );
                this.showNotifSuccess("notif_create_success", {
                  documentNumber: response.documentNo,
                });
              })
              .finally(() => (this.loading.saveAsDraft = false));
            break;
          default:
            break;
        }
      } else {
        switch (type) {
          case "submit":
            this.loading.submit = true;
            payload.status = STATUSDATA.NEED_APPROVAL;
            cashManagementServices
              .updateBankTransfer(payload, this.docId, "")
              .then(response => {
                this.$router.push(
                  "/cash-management/transactions/bank-transfer"
                );
                this.showNotifSuccess("notif_submit_success", {
                  documentNumber: response.documentNo,
                });
              })
              .finally(() => (this.loading.submit = false));
            break;
          case "update":
            if (this.statusData === STATUSDATA.DRAFT) {
              this.loading.update = true;
              cashManagementServices
                .updateBankTransfer(payload, this.docId, "/draft")
                .then(response => {
                  this.$router.push(
                    "/cash-management/transactions/bank-transfer"
                  );
                  this.showNotifSuccess("notif_update_success", {
                    documentNumber: response.documentNo,
                  });
                })
                .finally(() => (this.loading.update = false));
            } else {
              this.loading.update = true;
              payload.status = STATUSDATA.NEED_APPROVAL;
              cashManagementServices
                .updateBankTransfer(payload, this.docId, "")
                .then(response => {
                  this.$router.push(
                    "/cash-management/transactions/bank-transfer"
                  );
                  this.showNotifSuccess("notif_update_success", {
                    documentNumber: response.documentNo,
                  });
                })
                .finally(() => (this.loading.update = false));
            }
            break;
          case "approve":
            this.loading.approve = true;
            payload.status = STATUSDATA.APPROVED;
            cashManagementServices
              .updateBankTransfer(payload, this.docId, "")
              .then(response => {
                this.$router.push(
                  "/cash-management/transactions/bank-transfer"
                );
                this.showNotifSuccess("notif_approve_success", {
                  documentNumber: response.documentNo,
                });
              })
              .finally(() => (this.loading.approve = false));
            break;
          case "reject":
            this.loading.reject = true;
            payload.status = STATUSDATA.CANCELLED;
            cashManagementServices
              .updateBankTransfer(payload, this.docId, "")
              .then(response => {
                this.$router.push(
                  "/cash-management/transactions/bank-transfer"
                );
                this.showNotifSuccess("notif_reject_success", {
                  documentNumber: response.documentNo,
                });
              })
              .finally(() => (this.loading.reject = false));
            break;
          default:
            break;
        }
      }
    });
  }

  handleChange(info) {
    let fileList = [...info.fileList];
    fileList = fileList.slice(-1);
    fileList = fileList.map(file => {
      if (file.response) {
        file.url = file.response.url;
      }
      return file;
    });
    this.fileList = fileList;
    if (info.file.status === "done") {
      this.pathFile = info.file.response.url;
      this.$notification.success({
        description: `${info.file.name} file uploaded successfully`,
        message: "Success",
        duration: 30,
      });
    } else if (info.file.status === "error") {
      this.$notification.error({
        description: `${info.file.name} file upload failed.`,
        message: "Error",
        duration: 30,
      });
    }
  }

  beforeUpload(file) {
    const isLt5M = file.size;
    if (isLt5M >= ALLOWED_FILE_SIZE) {
      this.$notification.error({
        description: "File must smaller than 5MB!",
        message: "Error",
        duration: 30,
      });
    }
    return true;
  }

  customRequest(options) {
    const data = new FormData();
    data.append("file", options.file);
    cashManagementServices
      .createUploadFile(data, "asset")
      .then(response => {
        options.onSuccess(options);
        this.urlForSend = response.url;
      })
      .catch(err => {
        options.onError(err, options);
      });
  }

  searchDropdown(
    value = "",
    type: "bankDestination" | "branch" | "currency" | "bankSource"
  ): void {
    switch (type) {
      case "branch":
        this.getBranch(value);
        break;
      case "currency":
        this.getCurrency(value);
        break;
      case "bankSource":
        this.getListBankSource(value);
        break;
      case "bankDestination":
        this.getListBankDestination(value);
        break;
      default:
        break;
    }
  }

  getCurrency(value = ""): void {
    const { findAll } = useCurrency();
    const params = new RequestQueryParams();
    const builder = new SearchBuilder();
    this.loading.currency = true;
    if (value) {
      params.search = builder
        .push(["currencyCode", value], { like: "both" })
        .or()
        .push(["description", value], { like: "both" })
        .build();
    }
    findAll(params)
      .then(res => {
        this.dataCurrency = res.data;
      })
      .finally(() => (this.loading.currency = false));
  }

  getDetail(): void {
    cashManagementServices.detailBankTransfer(this.docId).then(res => {
      this.checkDropdown(res);
      this.statusData = res.status;
      this.urlForSend = res.attachment;
      this.dataBankCurrencySource = res.bankSourceCurrencyCode;
      if (res.attachment) {
        this.fileList = [
          ...this.fileList,
          {
            uid: "1",
            name: res.attachment.split("/")[
              res.attachment.split("/").length - 1
            ],
            status: "done",
            url: res.attachment,
            size: 0,
            type: "",
          },
        ];
      }
      switch (res.status) {
        case STATUSDATA.DRAFT:
          this.show.submit = true;
          this.show.saveAsDraft = false;
          this.show.update = true;
          this.show.reject = false;
          this.show.approve = false;
          break;
        case STATUSDATA.NEED_APPROVAL:
          this.show.submit = false;
          this.show.saveAsDraft = false;
          this.show.update = true;
          this.show.reject = true;
          this.show.approve = true;
          break;
        default:
          this.show.submit = false;
          this.show.saveAsDraft = false;
          this.show.update = false;
          this.show.reject = false;
          this.show.approve = false;
          for (const key in this.disabled) {
            this.disabled[key] = true;
          }
          break;
      }
      this.form.setFieldsValue({
        branch: res.branchId,
        transferNo: res.documentNo,
        transferDate: moment(res.transferDate),
        currency: res.currencyCode,
        transferAmount: res.transferAmount,
        bankSource: res.bankSourceId,
        description: res.description,
        bankDestination: res.bankDestinationId,
        bankAccountSource: res.bankSourceAccName,
        bankAccountNumberSource: res.bankSourceAccNo,
        bankNameSource: res.bankSourceName,
        bankNameDestination: res.bankDestinationName,
        bankBranchNameSource: res.bankSourceBranch,
        bankAccountDestination: res.bankDestinationAccName,
        bankAccountNumberDestination: res.bankDestinationAccNo,
        bankBranchNameDestination: res.bankDestinationBranch,
        rate: res.rates,
        status: res.status,
      });
      this.journalNumber.id = res.journalId;
      this.journalNumber.name = res.journalNumber;
      this.getListBankSource("");
    });
  }

  checkDropdown(value) {
    if (this.dataBranch.findIndex(item => item.id == value.branchId) == -1) {
      this.dataBranch.push({
        id: value.branchId,
        name: value.branchName,
        code: "",
        createdDate: "",
        modifiedDate: "",
        address: "",
      });
    }
    if (
      this.dataBankSource.findIndex(item => item.id == value.bankSourceId) == -1
    ) {
      this.dataBankSource.push({
        id: value.bankSourceId,
        bankName: value.bankSourceName,
        bankAccNumber: value.bankSourceAccNo,
      });
    }
    if (
      this.dataBankDestination.findIndex(
        item => item.id == value.bankDestinationId
      ) == -1
    ) {
      this.dataBankDestination.push({
        id: value.bankDestinationId,
        bankName: value.bankDestinationName,
        bankAccNumber: value.bankDestinationAccNo,
        disabled: false,
      });
    }
  }

  getListBankDestination(value = ""): void {
    const builder = new SearchBuilder();
    const params = new RequestQueryParams();
    params.sorts = "bankName:asc";

    if (value) {
      params.search = builder
        .push(["bankAccName", value], { like: "both" })
        .or()
        .push(["bankAccNumber", value], { like: "both" })
        .or()
        .push(["bankName", value], { like: "both" })
        .build();
    }

    this.loading.bankDestination = true;
    masterServices
      .listCompanyBank(params)
      .then(res => {
        this.dataBankDestination = res.data;
      })
      .finally(() => {
        this.loading.bankDestination = false;
      });
  }

  /**
   * @param value search value
   */
  getListBankSource(value = ""): void {
    const builder = new SearchBuilder();
    const currencyCode = this.form.getFieldValue("currency");
    const defaultQuery = builder
      .push(["currency.currencyCode", currencyCode], {
        like: "both",
      })
      .build();
    const params = new RequestQueryParams(defaultQuery);
    params.sorts = "bankName:asc";

    const q: string[] = [defaultQuery];
    if (value) {
      builder.destroy();
      const search = builder
        .push(["bankAccName", value], { like: "both" })
        .or()
        .push(["bankAccNumber", value], { like: "both" })
        .or()
        .push(["bankName", value], { like: "both" })
        .build();
      q.unshift(search);
      params.search = q.join(builder.AND);
    }

    this.loading.bankSource = true;
    masterServices
      .listCompanyBank(params)
      .then(res => {
        this.dataBankSource = res.data;
      })
      .finally(() => {
        this.loading.bankSource = false;
      });
  }

  getBranch(value = ""): void {
    const builder = new SearchBuilder();
    const params = new RequestQueryParams();
    if (value) {
      params.search = builder
        .push(["name", value], { like: "both" })
        .or()
        .push(["code", value], { like: "both" })
        .or()
        .push(["address", value], { like: "both" })
        .build();
    }
    this.loading.branch = true;
    logisticServices
      .listWarehouseBranch(params, "")
      .then(response => {
        this.dataBranch = response.data;
      })
      .finally(() => (this.loading.branch = false));
  }

  handlePrint(): void {
    const { toObjectUrl } = useBlob();
    const { print } = useBankTransfer();
    this.loading.print = true;
    print(this.docId)
      .then(response => {
        printJS(toObjectUrl(response));
      })
      .finally(() => {
        this.loading.print = false;
      });
  }
}
