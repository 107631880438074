var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-layout-content",
        [
          _c(
            "a-row",
            [
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "a-card",
                    { attrs: { title: "Internal Contract" } },
                    [
                      _c(
                        "a-row",
                        [
                          _c(
                            "a-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "a-form",
                                _vm._b(
                                  {
                                    attrs: {
                                      layout: "vertical",
                                      form: _vm.form
                                    },
                                    on: {
                                      submit: function($event) {
                                        $event.preventDefault()
                                        return _vm.submitForm.apply(
                                          null,
                                          arguments
                                        )
                                      }
                                    }
                                  },
                                  "a-form",
                                  _vm.formItemLayout,
                                  false
                                ),
                                [
                                  _c(
                                    "a-row",
                                    [
                                      _c(
                                        "a-col",
                                        {
                                          attrs: {
                                            xs: 24,
                                            sm: 24,
                                            md: 12,
                                            lg: 12,
                                            xl: 12
                                          }
                                        },
                                        [
                                          _c(
                                            "a-form-model-item",
                                            {
                                              attrs: {
                                                label: _vm.$t(
                                                  _vm.formRules
                                                    .internalContractNo.label
                                                )
                                              }
                                            },
                                            [
                                              _c("a-input", {
                                                directives: [
                                                  {
                                                    name: "decorator",
                                                    rawName: "v-decorator",
                                                    value:
                                                      _vm.formRules
                                                        .internalContractNo
                                                        .decorator,
                                                    expression:
                                                      "formRules.internalContractNo.decorator"
                                                  }
                                                ],
                                                attrs: {
                                                  name:
                                                    _vm.formRules
                                                      .internalContractNo.name,
                                                  placeholder: _vm.$t(
                                                    _vm.formRules
                                                      .internalContractNo
                                                      .placeholder
                                                  ),
                                                  disabled: true
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-form-model-item",
                                            {
                                              attrs: {
                                                label: _vm.$t(
                                                  _vm.formRules.branchName.label
                                                )
                                              }
                                            },
                                            [
                                              _c(
                                                "a-select",
                                                {
                                                  directives: [
                                                    {
                                                      name: "decorator",
                                                      rawName: "v-decorator",
                                                      value:
                                                        _vm.formRules.branchName
                                                          .decorator,
                                                      expression:
                                                        "formRules.branchName.decorator"
                                                    }
                                                  ],
                                                  attrs: {
                                                    name:
                                                      _vm.formRules.branchName
                                                        .name,
                                                    placeholder: _vm.$t(
                                                      _vm.formRules.branchName
                                                        .placeholder
                                                    ),
                                                    showSearch: true,
                                                    "option-filter-prop":
                                                      "children",
                                                    "filter-option":
                                                      _vm.filterOption,
                                                    loading:
                                                      _vm.loading.branchName,
                                                    allowClear: true,
                                                    disabled: _vm.view
                                                  },
                                                  on: {
                                                    search: function(val) {
                                                      return _vm.debounce(
                                                        function() {
                                                          return _vm.getListBranch(
                                                            val
                                                          )
                                                        },
                                                        400
                                                      )
                                                    },
                                                    change:
                                                      _vm.handleChangeBranch
                                                  }
                                                },
                                                _vm._l(
                                                  _vm.dataListBranch,
                                                  function(data, index) {
                                                    return _c(
                                                      "a-select-option",
                                                      {
                                                        key: index,
                                                        attrs: {
                                                          value: data.id
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "a-tooltip",
                                                          [
                                                            _c(
                                                              "template",
                                                              { slot: "title" },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      "" +
                                                                        data.name
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  "" + data.name
                                                                ) +
                                                                " "
                                                            )
                                                          ],
                                                          2
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-form-model-item",
                                            {
                                              attrs: {
                                                label: _vm.$t(
                                                  _vm.formRules.referenceNo
                                                    .label
                                                )
                                              }
                                            },
                                            [
                                              _c("a-input", {
                                                directives: [
                                                  {
                                                    name: "decorator",
                                                    rawName: "v-decorator",
                                                    value:
                                                      _vm.formRules.referenceNo
                                                        .decorator,
                                                    expression:
                                                      "formRules.referenceNo.decorator"
                                                  }
                                                ],
                                                attrs: {
                                                  name:
                                                    _vm.formRules.referenceNo
                                                      .name,
                                                  placeholder: _vm.$t(
                                                    _vm.formRules.referenceNo
                                                      .placeholder
                                                  ),
                                                  disabled: _vm.view
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-form-model-item",
                                            {
                                              attrs: {
                                                label: _vm.$t(
                                                  _vm.formRules
                                                    .internalContractDate.label
                                                )
                                              }
                                            },
                                            [
                                              _c("a-date-picker", {
                                                directives: [
                                                  {
                                                    name: "decorator",
                                                    rawName: "v-decorator",
                                                    value:
                                                      _vm.formRules
                                                        .internalContractDate
                                                        .decorator,
                                                    expression:
                                                      "formRules.internalContractDate.decorator"
                                                  }
                                                ],
                                                attrs: {
                                                  name:
                                                    _vm.formRules
                                                      .internalContractDate
                                                      .name,
                                                  placeholder: _vm.$t(
                                                    _vm.formRules
                                                      .internalContractDate
                                                      .placeholder
                                                  ),
                                                  format:
                                                    _vm.DEFAULT_DATE_FORMAT,
                                                  disabled: _vm.view
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-form-model-item",
                                            {
                                              attrs: {
                                                label: _vm.$t(
                                                  _vm.formRules.customerName
                                                    .label
                                                )
                                              }
                                            },
                                            [
                                              _c(
                                                "a-select",
                                                {
                                                  directives: [
                                                    {
                                                      name: "decorator",
                                                      rawName: "v-decorator",
                                                      value:
                                                        _vm.formRules
                                                          .customerName
                                                          .decorator,
                                                      expression:
                                                        "formRules.customerName.decorator"
                                                    }
                                                  ],
                                                  attrs: {
                                                    name:
                                                      _vm.formRules.customerName
                                                        .name,
                                                    placeholder: _vm.$t(
                                                      _vm.formRules.customerName
                                                        .placeholder
                                                    ),
                                                    showSearch: true,
                                                    "option-filter-prop":
                                                      "children",
                                                    "filter-option":
                                                      _vm.filterOption,
                                                    loading:
                                                      _vm.loading.listCustomer,
                                                    allowClear: true,
                                                    disabled: _vm.view
                                                  },
                                                  on: {
                                                    search: function(val) {
                                                      return _vm.debounce(
                                                        function() {
                                                          return _vm.getListCustomer(
                                                            val
                                                          )
                                                        },
                                                        400
                                                      )
                                                    },
                                                    change:
                                                      _vm.handleChangeCustomer
                                                  }
                                                },
                                                _vm._l(
                                                  _vm.dataListCustomer,
                                                  function(data, index) {
                                                    return _c(
                                                      "a-select-option",
                                                      {
                                                        key: index,
                                                        attrs: {
                                                          value: data.id
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "a-tooltip",
                                                          [
                                                            _c(
                                                              "template",
                                                              { slot: "title" },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      "" +
                                                                        data.fullName.replace(
                                                                          "null",
                                                                          ""
                                                                        )
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  "" +
                                                                    data.fullName.replace(
                                                                      "null",
                                                                      ""
                                                                    )
                                                                ) +
                                                                " "
                                                            )
                                                          ],
                                                          2
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-form-model-item",
                                            {
                                              attrs: {
                                                label: _vm.$t(
                                                  _vm.formRules.shipTo.label
                                                )
                                              }
                                            },
                                            [
                                              _c(
                                                "a-select",
                                                {
                                                  directives: [
                                                    {
                                                      name: "decorator",
                                                      rawName: "v-decorator",
                                                      value:
                                                        _vm.formRules.shipTo
                                                          .decorator,
                                                      expression:
                                                        "formRules.shipTo.decorator"
                                                    }
                                                  ],
                                                  attrs: {
                                                    name:
                                                      _vm.formRules.shipTo.name,
                                                    placeholder: _vm.$t(
                                                      _vm.formRules.shipTo
                                                        .placeholder
                                                    ),
                                                    showSearch: true,
                                                    "option-filter-prop":
                                                      "children",
                                                    "filter-option":
                                                      _vm.filterOption,
                                                    loading: _vm.loading.shipTo,
                                                    allowClear: true,
                                                    disabled: _vm.isChangeLocation
                                                      ? false
                                                      : _vm.view
                                                  },
                                                  on: {
                                                    change:
                                                      _vm.handleChangeShipTo
                                                  }
                                                },
                                                _vm._l(_vm.dataShipTo, function(
                                                  data,
                                                  index
                                                ) {
                                                  return _c(
                                                    "a-select-option",
                                                    {
                                                      key: index,
                                                      attrs: {
                                                        value: data.address
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "a-tooltip",
                                                        [
                                                          _c(
                                                            "template",
                                                            { slot: "title" },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    "" +
                                                                      data.address
                                                                  ) +
                                                                  " "
                                                              )
                                                            ]
                                                          ),
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                "" +
                                                                  data.address
                                                              ) +
                                                              " "
                                                          )
                                                        ],
                                                        2
                                                      )
                                                    ],
                                                    1
                                                  )
                                                }),
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-form-model-item",
                                            {
                                              attrs: {
                                                label: _vm.$t(
                                                  _vm.formRules.billTo.label
                                                )
                                              }
                                            },
                                            [
                                              _c(
                                                "a-select",
                                                {
                                                  directives: [
                                                    {
                                                      name: "decorator",
                                                      rawName: "v-decorator",
                                                      value:
                                                        _vm.formRules.billTo
                                                          .decorator,
                                                      expression:
                                                        "formRules.billTo.decorator"
                                                    }
                                                  ],
                                                  attrs: {
                                                    name:
                                                      _vm.formRules.billTo.name,
                                                    placeholder: _vm.$t(
                                                      _vm.formRules.billTo
                                                        .placeholder
                                                    ),
                                                    showSearch: true,
                                                    "option-filter-prop":
                                                      "children",
                                                    "filter-option":
                                                      _vm.filterOption,
                                                    loading: _vm.loading.billTo,
                                                    allowClear: true,
                                                    disabled: _vm.isChangeLocation
                                                      ? false
                                                      : _vm.view
                                                  }
                                                },
                                                _vm._l(_vm.dataBillTo, function(
                                                  data,
                                                  index
                                                ) {
                                                  return _c(
                                                    "a-select-option",
                                                    {
                                                      key: index,
                                                      attrs: {
                                                        value: data.address
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "a-tooltip",
                                                        [
                                                          _c(
                                                            "template",
                                                            { slot: "title" },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    "" +
                                                                      data.address
                                                                  ) +
                                                                  " "
                                                              )
                                                            ]
                                                          ),
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                "" +
                                                                  data.address
                                                              ) +
                                                              " "
                                                          )
                                                        ],
                                                        2
                                                      )
                                                    ],
                                                    1
                                                  )
                                                }),
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-form-model-item",
                                            {
                                              attrs: {
                                                label: _vm.$t(
                                                  _vm.formRules.picName.label
                                                )
                                              }
                                            },
                                            [
                                              _c(
                                                "a-select",
                                                {
                                                  directives: [
                                                    {
                                                      name: "decorator",
                                                      rawName: "v-decorator",
                                                      value:
                                                        _vm.formRules.picName
                                                          .decorator,
                                                      expression:
                                                        "formRules.picName.decorator"
                                                    }
                                                  ],
                                                  attrs: {
                                                    name:
                                                      _vm.formRules.picName
                                                        .name,
                                                    placeholder: _vm.$t(
                                                      _vm.formRules.picName
                                                        .placeholder
                                                    ),
                                                    showSearch: true,
                                                    "option-filter-prop":
                                                      "children",
                                                    "filter-option":
                                                      _vm.filterOption,
                                                    loading:
                                                      _vm.loading.picName,
                                                    allowClear: true,
                                                    disabled: _vm.isChangeLocation
                                                      ? false
                                                      : _vm.view
                                                  }
                                                },
                                                _vm._l(
                                                  _vm.dataPicName,
                                                  function(data, index) {
                                                    return _c(
                                                      "a-select-option",
                                                      {
                                                        key: index,
                                                        attrs: {
                                                          value: data.picName
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "a-tooltip",
                                                          [
                                                            _c(
                                                              "template",
                                                              { slot: "title" },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      data.picName
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  data.picName
                                                                ) +
                                                                " "
                                                            )
                                                          ],
                                                          2
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-form-model-item",
                                            {
                                              attrs: {
                                                label: _vm.$t(
                                                  _vm.formRules.currency.label
                                                )
                                              }
                                            },
                                            [
                                              _c(
                                                "a-select",
                                                {
                                                  directives: [
                                                    {
                                                      name: "decorator",
                                                      rawName: "v-decorator",
                                                      value:
                                                        _vm.formRules.currency
                                                          .decorator,
                                                      expression:
                                                        "formRules.currency.decorator"
                                                    }
                                                  ],
                                                  attrs: {
                                                    name:
                                                      _vm.formRules.currency
                                                        .name,
                                                    placeholder: _vm.$t(
                                                      _vm.formRules.currency
                                                        .placeholder
                                                    ),
                                                    showSearch: true,
                                                    "option-filter-prop":
                                                      "children",
                                                    "filter-option":
                                                      _vm.filterOption,
                                                    loading:
                                                      _vm.loading.currency,
                                                    allowClear: true,
                                                    disabled: _vm.view
                                                  },
                                                  on: {
                                                    search: function(val) {
                                                      return _vm.debounce(
                                                        function() {
                                                          return _vm.getCurrency(
                                                            val
                                                          )
                                                        },
                                                        400
                                                      )
                                                    }
                                                  }
                                                },
                                                _vm._l(
                                                  _vm.dataCurrency,
                                                  function(data, index) {
                                                    return _c(
                                                      "a-select-option",
                                                      {
                                                        key: index,
                                                        attrs: {
                                                          value: data.id
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "a-tooltip",
                                                          [
                                                            _c(
                                                              "template",
                                                              { slot: "title" },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      data.currencyCode
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  data.currencyCode
                                                                ) +
                                                                " "
                                                            )
                                                          ],
                                                          2
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-form-model-item",
                                            {
                                              attrs: {
                                                label: _vm.$t(
                                                  _vm.formRules.rentType.label
                                                )
                                              }
                                            },
                                            [
                                              _c(
                                                "a-select",
                                                {
                                                  directives: [
                                                    {
                                                      name: "decorator",
                                                      rawName: "v-decorator",
                                                      value:
                                                        _vm.formRules.rentType
                                                          .decorator,
                                                      expression:
                                                        "formRules.rentType.decorator"
                                                    }
                                                  ],
                                                  attrs: {
                                                    name:
                                                      _vm.formRules.rentType
                                                        .name,
                                                    placeholder: _vm.$t(
                                                      _vm.formRules.rentType
                                                        .placeholder
                                                    ),
                                                    "option-filter-prop":
                                                      "children",
                                                    "filter-option":
                                                      _vm.filterOption,
                                                    allowClear: true,
                                                    disabled: _vm.isRevised
                                                      ? true
                                                      : _vm.view
                                                  },
                                                  on: {
                                                    change:
                                                      _vm.handleChangeRentType
                                                  }
                                                },
                                                _vm._l(
                                                  _vm.dataRentType,
                                                  function(data, index) {
                                                    return _c(
                                                      "a-select-option",
                                                      {
                                                        key: index,
                                                        attrs: {
                                                          value: data.value
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "a-tooltip",
                                                          [
                                                            _c(
                                                              "template",
                                                              { slot: "title" },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      data.label
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  data.label
                                                                ) +
                                                                " "
                                                            )
                                                          ],
                                                          2
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-form-model-item",
                                            {
                                              attrs: {
                                                label: _vm.$t(
                                                  _vm.formRules.rentPeriod.label
                                                )
                                              }
                                            },
                                            [
                                              _c("a-input-number", {
                                                directives: [
                                                  {
                                                    name: "decorator",
                                                    rawName: "v-decorator",
                                                    value:
                                                      _vm.formRules.rentPeriod
                                                        .decorator,
                                                    expression:
                                                      "formRules.rentPeriod.decorator"
                                                  }
                                                ],
                                                staticClass: "w-100",
                                                attrs: {
                                                  min: 0,
                                                  name:
                                                    _vm.formRules.rentPeriod
                                                      .name,
                                                  placeholder: _vm.$t(
                                                    _vm.formRules.rentPeriod
                                                      .placeholder
                                                  ),
                                                  parser: _vm.InputNumberOnly,
                                                  disabled: _vm.view
                                                },
                                                on: {
                                                  change:
                                                    _vm.handleChangeRentPeriod
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-form-model-item",
                                            {
                                              attrs: {
                                                label: _vm.$t(
                                                  _vm.formRules.rentStartingDate
                                                    .label
                                                )
                                              }
                                            },
                                            [
                                              _c("a-date-picker", {
                                                directives: [
                                                  {
                                                    name: "decorator",
                                                    rawName: "v-decorator",
                                                    value:
                                                      _vm.formRules
                                                        .rentStartingDate
                                                        .decorator,
                                                    expression:
                                                      "formRules.rentStartingDate.decorator"
                                                  }
                                                ],
                                                attrs: {
                                                  name:
                                                    _vm.formRules
                                                      .rentStartingDate.name,
                                                  placeholder: _vm.$t(
                                                    _vm.formRules
                                                      .rentStartingDate
                                                      .placeholder
                                                  ),
                                                  format:
                                                    _vm.DEFAULT_DATE_FORMAT,
                                                  disabled: _vm.view
                                                },
                                                on: {
                                                  change:
                                                    _vm.handleChangeRentStartingDate
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-form-model-item",
                                            {
                                              attrs: {
                                                label: _vm.$t(
                                                  _vm.formRules.rentPeriodRange
                                                    .label
                                                )
                                              }
                                            },
                                            [
                                              _c("a-input", {
                                                directives: [
                                                  {
                                                    name: "decorator",
                                                    rawName: "v-decorator",
                                                    value:
                                                      _vm.formRules
                                                        .rentPeriodRange
                                                        .decorator,
                                                    expression:
                                                      "formRules.rentPeriodRange.decorator"
                                                  }
                                                ],
                                                attrs: {
                                                  name:
                                                    _vm.formRules
                                                      .rentPeriodRange.name,
                                                  placeholder: _vm.$t(
                                                    _vm.formRules
                                                      .rentPeriodRange
                                                      .placeholder
                                                  ),
                                                  disabled: true
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "a-col",
                                        {
                                          attrs: {
                                            xs: 24,
                                            sm: 24,
                                            md: 12,
                                            lg: 12,
                                            xl: 12
                                          }
                                        },
                                        [
                                          _c(
                                            "a-form-model-item",
                                            {
                                              attrs: {
                                                label: _vm.$t(
                                                  _vm.formRules.billingType
                                                    .label
                                                )
                                              }
                                            },
                                            [
                                              _c(
                                                "a-select",
                                                {
                                                  directives: [
                                                    {
                                                      name: "decorator",
                                                      rawName: "v-decorator",
                                                      value:
                                                        _vm.formRules
                                                          .billingType
                                                          .decorator,
                                                      expression:
                                                        "formRules.billingType.decorator"
                                                    }
                                                  ],
                                                  attrs: {
                                                    name:
                                                      _vm.formRules.billingType
                                                        .name,
                                                    placeholder: _vm.$t(
                                                      _vm.formRules.billingType
                                                        .placeholder
                                                    ),
                                                    showSearch: false,
                                                    "option-filter-prop":
                                                      "children",
                                                    "filter-option":
                                                      _vm.filterOption,
                                                    loading:
                                                      _vm.loading.billingType,
                                                    allowClear: true,
                                                    disabled: _vm.view
                                                  },
                                                  on: {
                                                    search: function(val) {
                                                      return _vm.debounce(
                                                        function() {
                                                          return _vm.getListBillingType(
                                                            val
                                                          )
                                                        },
                                                        400
                                                      )
                                                    }
                                                  }
                                                },
                                                _vm._l(
                                                  _vm.dataListBillingType,
                                                  function(data, index) {
                                                    return _c(
                                                      "a-select-option",
                                                      {
                                                        key: index,
                                                        attrs: {
                                                          value: data.value
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "a-tooltip",
                                                          [
                                                            _c(
                                                              "template",
                                                              { slot: "title" },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      data.name
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  data.name
                                                                ) +
                                                                " "
                                                            )
                                                          ],
                                                          2
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-form-item",
                                            {
                                              attrs: {
                                                label: _vm.$t(
                                                  _vm.formRules.termOfCondition
                                                    .label
                                                )
                                              }
                                            },
                                            [
                                              _c("a-textarea", {
                                                directives: [
                                                  {
                                                    name: "decorator",
                                                    rawName: "v-decorator",
                                                    value:
                                                      _vm.formRules
                                                        .termOfCondition
                                                        .decorator,
                                                    expression:
                                                      "formRules.termOfCondition.decorator"
                                                  }
                                                ],
                                                attrs: {
                                                  name:
                                                    _vm.formRules
                                                      .termOfCondition.name,
                                                  placeholder: _vm.$t(
                                                    _vm.formRules
                                                      .termOfCondition
                                                      .placeholder
                                                  ),
                                                  "auto-size": {
                                                    minRows: 3,
                                                    maxRows: 5
                                                  },
                                                  disabled: _vm.view
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-form-model-item",
                                            {
                                              attrs: {
                                                label: _vm.$t(
                                                  _vm.formRules.fileContract
                                                    .label
                                                ),
                                                help:
                                                  "Maximum file size is 5MB and accepted file extension is PDF, JPG, PNG, XLSX"
                                              }
                                            },
                                            [
                                              _c(
                                                "a-upload",
                                                {
                                                  directives: [
                                                    {
                                                      name: "decorator",
                                                      rawName: "v-decorator",
                                                      value:
                                                        _vm.formRules
                                                          .fileContract
                                                          .decorator,
                                                      expression:
                                                        "formRules.fileContract.decorator"
                                                    }
                                                  ],
                                                  attrs: {
                                                    disabled: _vm.view,
                                                    name: "file",
                                                    multiple: false,
                                                    "file-list": _vm.fileList,
                                                    "before-upload":
                                                      _vm.beforeUpload,
                                                    remove: _vm.handleRemove,
                                                    customRequest:
                                                      _vm.uploadFile,
                                                    accept:
                                                      ".pdf, .png, .jpg, .xlsx"
                                                  },
                                                  on: {
                                                    change:
                                                      _vm.handleChangeFileContract
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "a-button",
                                                    [
                                                      _c("a-icon", {
                                                        staticStyle: {
                                                          "vertical-align":
                                                            "text-top"
                                                        },
                                                        attrs: {
                                                          type: "upload"
                                                        }
                                                      }),
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t("lbl_upload")
                                                          )
                                                        )
                                                      ])
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-form-model-item",
                                            {
                                              attrs: {
                                                label: _vm.$t(
                                                  _vm.formRules.sales.label
                                                )
                                              }
                                            },
                                            [
                                              _c(
                                                "a-select",
                                                {
                                                  directives: [
                                                    {
                                                      name: "decorator",
                                                      rawName: "v-decorator",
                                                      value:
                                                        _vm.formRules.sales
                                                          .decorator,
                                                      expression:
                                                        "formRules.sales.decorator"
                                                    }
                                                  ],
                                                  attrs: {
                                                    name:
                                                      _vm.formRules.sales.name,
                                                    placeholder: _vm.$t(
                                                      _vm.formRules.sales
                                                        .placeholder
                                                    ),
                                                    showSearch: true,
                                                    "option-filter-prop":
                                                      "children",
                                                    "filter-option":
                                                      _vm.filterOption,
                                                    loading: _vm.loading.sales,
                                                    allowClear: true,
                                                    disabled: _vm.view
                                                  },
                                                  on: {
                                                    search: function(val) {
                                                      return _vm.debounce(
                                                        function() {
                                                          return _vm.getListSales(
                                                            val
                                                          )
                                                        },
                                                        400
                                                      )
                                                    }
                                                  }
                                                },
                                                _vm._l(
                                                  _vm.dataListSales,
                                                  function(data, index) {
                                                    return _c(
                                                      "a-select-option",
                                                      {
                                                        key: index,
                                                        attrs: {
                                                          value: data.id
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "a-tooltip",
                                                          [
                                                            _c(
                                                              "template",
                                                              { slot: "title" },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      "" +
                                                                        data.fullName.replace(
                                                                          "null",
                                                                          ""
                                                                        )
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  "" +
                                                                    data.fullName.replace(
                                                                      "null",
                                                                      ""
                                                                    )
                                                                ) +
                                                                " "
                                                            )
                                                          ],
                                                          2
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-form-model-item",
                                            {
                                              attrs: {
                                                label: _vm.$t(
                                                  _vm.formRules.status.label
                                                )
                                              }
                                            },
                                            [
                                              _c("a-input", {
                                                directives: [
                                                  {
                                                    name: "decorator",
                                                    rawName: "v-decorator",
                                                    value:
                                                      _vm.formRules.status
                                                        .decorator,
                                                    expression:
                                                      "formRules.status.decorator"
                                                  }
                                                ],
                                                attrs: {
                                                  name:
                                                    _vm.formRules.status.name,
                                                  placeholder: _vm.$t(
                                                    _vm.formRules.status
                                                      .placeholder
                                                  ),
                                                  disabled: ""
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-form-model-item",
                                            {
                                              attrs: {
                                                label: _vm.$t(
                                                  _vm.formRules.revised.label
                                                )
                                              }
                                            },
                                            [
                                              _c("a-input", {
                                                directives: [
                                                  {
                                                    name: "decorator",
                                                    rawName: "v-decorator",
                                                    value:
                                                      _vm.formRules.revised
                                                        .decorator,
                                                    expression:
                                                      "formRules.revised.decorator"
                                                  }
                                                ],
                                                attrs: {
                                                  name:
                                                    _vm.formRules.revised.name,
                                                  placeholder: _vm.$t(
                                                    _vm.formRules.revised
                                                      .placeholder
                                                  ),
                                                  disabled: ""
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-form-model-item",
                                            {
                                              attrs: {
                                                label: _vm.$t(
                                                  _vm.formRules.createdBy.label
                                                )
                                              }
                                            },
                                            [
                                              _c("a-input", {
                                                directives: [
                                                  {
                                                    name: "decorator",
                                                    rawName: "v-decorator",
                                                    value:
                                                      _vm.formRules.createdBy
                                                        .decorator,
                                                    expression:
                                                      "formRules.createdBy.decorator"
                                                  }
                                                ],
                                                attrs: {
                                                  name:
                                                    _vm.formRules.createdBy
                                                      .name,
                                                  placeholder: _vm.$t(
                                                    _vm.formRules.createdBy
                                                      .placeholder
                                                  ),
                                                  disabled: ""
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-form-model-item",
                                            {
                                              attrs: {
                                                label: _vm.$t(
                                                  _vm.formRules.approver.label
                                                )
                                              }
                                            },
                                            [
                                              _c(
                                                "a-select",
                                                {
                                                  directives: [
                                                    {
                                                      name: "decorator",
                                                      rawName: "v-decorator",
                                                      value:
                                                        _vm.formRules.approver
                                                          .decorator,
                                                      expression:
                                                        "formRules.approver.decorator"
                                                    }
                                                  ],
                                                  attrs: {
                                                    name:
                                                      _vm.formRules.approver
                                                        .name,
                                                    placeholder: _vm.$t(
                                                      _vm.formRules.approver
                                                        .placeholder
                                                    ),
                                                    showSearch: false,
                                                    "option-filter-prop":
                                                      "children",
                                                    "filter-option":
                                                      _vm.filterOption,
                                                    loading:
                                                      _vm.loading.revised,
                                                    allowClear: true,
                                                    disabled: ""
                                                  },
                                                  on: {
                                                    search: function(val) {
                                                      return _vm.debounce(
                                                        function() {
                                                          return _vm.getListRevised(
                                                            val
                                                          )
                                                        },
                                                        400
                                                      )
                                                    }
                                                  }
                                                },
                                                _vm._l(
                                                  _vm.dataApprover,
                                                  function(data, index) {
                                                    return _c(
                                                      "a-select-option",
                                                      {
                                                        key: index,
                                                        attrs: {
                                                          value: data.id
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "a-tooltip",
                                                          [
                                                            _c(
                                                              "template",
                                                              { slot: "title" },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      data.name
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  data.name
                                                                ) +
                                                                " "
                                                            )
                                                          ],
                                                          2
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-form-model-item",
                                            {
                                              attrs: {
                                                label: _vm.$t(
                                                  _vm.formRules.notes.label
                                                )
                                              }
                                            },
                                            [
                                              _c("a-textarea", {
                                                directives: [
                                                  {
                                                    name: "decorator",
                                                    rawName: "v-decorator",
                                                    value:
                                                      _vm.formRules.notes
                                                        .decorator,
                                                    expression:
                                                      "formRules.notes.decorator"
                                                  }
                                                ],
                                                attrs: {
                                                  name:
                                                    _vm.formRules.notes.name,
                                                  placeholder: _vm.$t(
                                                    _vm.formRules.notes
                                                      .placeholder
                                                  ),
                                                  "auto-size": {
                                                    minRows: 3,
                                                    maxRows: 5
                                                  },
                                                  disabled: ""
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-form-model-item",
                                            {
                                              staticStyle: {
                                                "margin-top": "1rem"
                                              },
                                              attrs: {
                                                label: _vm.$t(
                                                  _vm.formRules.services.label
                                                )
                                              }
                                            },
                                            [
                                              _c("a-switch", {
                                                attrs: {
                                                  disabled:
                                                    _vm.isActualNew ||
                                                    _vm.isActualDraft ||
                                                    _vm.isActualNeedApproval
                                                      ? false
                                                      : true
                                                },
                                                model: {
                                                  value: _vm.services,
                                                  callback: function($$v) {
                                                    _vm.services = $$v
                                                  },
                                                  expression: "services"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            { staticClass: "mt-4", attrs: { span: 24 } },
                            [
                              _c(
                                "a-tabs",
                                { attrs: { type: "card" } },
                                [
                                  _c(
                                    "a-tab-pane",
                                    {
                                      key: "1",
                                      staticClass: "bordertabs p-2",
                                      attrs: {
                                        tab: "List of Internal Contract"
                                      }
                                    },
                                    [
                                      _c(
                                        "a-row",
                                        [
                                          _c(
                                            "a-col",
                                            { attrs: { span: 24 } },
                                            [
                                              _c(
                                                "a-button",
                                                {
                                                  staticStyle: {
                                                    margin: "10px 0"
                                                  },
                                                  attrs: {
                                                    type: "primary",
                                                    disabled: _vm.view
                                                  },
                                                  on: {
                                                    click: _vm.handleAddRow
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t("lbl_add_row")
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-col",
                                            { attrs: { span: 24 } },
                                            [
                                              _c("TableCustom", {
                                                attrs: {
                                                  dataSource: _vm.dataSource,
                                                  columns: _vm.columnsTable,
                                                  scroll: {
                                                    x: "calc(700px + 50%)",
                                                    y: 500
                                                  },
                                                  paginationcustom: false,
                                                  defaultPagination: {
                                                    showTotal: function(total) {
                                                      return _vm.$t(
                                                        "lbl_total_items",
                                                        { total: total }
                                                      )
                                                    },
                                                    showSizeChanger: true
                                                  },
                                                  loading: _vm.loading.table,
                                                  handleSelect:
                                                    _vm.handleSelect,
                                                  handleInput: _vm.handleInput,
                                                  handleSearchSelectTable:
                                                    _vm.handleSearchSelectTable,
                                                  handleCheckBox:
                                                    _vm.handleCheckBoxTable,
                                                  footerCreateInternalContract:
                                                    _vm.footerCreateInternalContract,
                                                  onSelectChange:
                                                    _vm.isApproved ||
                                                    _vm.isPartialEnded
                                                      ? _vm.onSelectChange
                                                      : null,
                                                  selectedRowKeys:
                                                    _vm.isApproved ||
                                                    _vm.isPartialEnded
                                                      ? _vm.selectedRowKeys
                                                      : null,
                                                  getCheckboxProps:
                                                    _vm.isApproved ||
                                                    _vm.isPartialEnded
                                                      ? _vm.getCheckboxProps
                                                      : null
                                                },
                                                on: {
                                                  "on-delete":
                                                    _vm.responseDeleteTable
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "a-tab-pane",
                                    {
                                      key: "2",
                                      staticClass: "bordertabs p-2",
                                      attrs: { tab: "Product Service" }
                                    },
                                    [
                                      _c("ProductServiceTab", {
                                        attrs: {
                                          dataRent: _vm.dataRent,
                                          rentType: _vm.rentType,
                                          view: _vm.view,
                                          detailDataSourceProductServices:
                                            _vm.detailDataSourceProductServices,
                                          detailFooterTotalProductService:
                                            _vm.detailFooterTotalProductService,
                                          detail: _vm.detail
                                        },
                                        on: {
                                          "set-dataSource-productServices":
                                            _vm.setDataSourceProductServices,
                                          "set-total-productServices":
                                            _vm.setTotalProductServices,
                                          "set-delete-productServices":
                                            _vm.setDeleteProductServices,
                                          onSelectChange: _vm.onSelectChange
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          !_vm.viewHistory
                            ? _c(
                                "a-col",
                                {
                                  staticStyle: { "margin-top": "10px" },
                                  attrs: { span: 24, align: "end" }
                                },
                                [
                                  _c(
                                    "a-space",
                                    [
                                      _c(
                                        "a-button",
                                        { on: { click: _vm.handleBack } },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("lbl_back")) +
                                              " "
                                          )
                                        ]
                                      ),
                                      !_vm.isCancelled &&
                                      !_vm.isPartialEnded &&
                                      _vm.$can("cancel", "internal-contract")
                                        ? _c(
                                            "a-button",
                                            {
                                              attrs: {
                                                type: "danger",
                                                disabled:
                                                  _vm.isNew ||
                                                  _vm.detail.isCanCancel
                                                    ? false
                                                    : true
                                              },
                                              on: { click: _vm.handleCancel }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.isNew
                                                      ? _vm.$t("lbl_reset")
                                                      : _vm.$t("lbl_cancel")
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.isNew &&
                                      _vm.$can("create", "internal-contract")
                                        ? _c(
                                            "a-button",
                                            {
                                              attrs: {
                                                loading: _vm.loading.submit,
                                                type: "primary"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.onSubmit("Draft")
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.$t("lbl_save")) +
                                                  " as Draft "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      (_vm.isNew || _vm.isDraft) &&
                                      (_vm.$can(
                                        "update",
                                        "internal-contract"
                                      ) ||
                                        _vm.$can("create", "internal-contract"))
                                        ? _c(
                                            "a-button",
                                            {
                                              attrs: {
                                                loading: _vm.loading.submit,
                                                type: "primary"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.onSubmit(
                                                    "Need Approval"
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v(" Submit ")]
                                          )
                                        : _vm._e(),
                                      (_vm.isNeedApproval ||
                                        _vm.isRejected ||
                                        _vm.isApproved) &&
                                      !_vm.isSo &&
                                      _vm.$can("update", "internal-contract")
                                        ? _c(
                                            "a-button",
                                            {
                                              attrs: { type: "primary" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.onSubmit("Update")
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.$t("lbl_update")) +
                                                  " "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.isAcceptanceOfOrder &&
                                      !_vm.isCancelled &&
                                      _vm.$can(
                                        "change-unit",
                                        "internal-contract"
                                      )
                                        ? _c(
                                            "a-button",
                                            {
                                              attrs: { type: "primary" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.onSubmit(
                                                    "Change Unit"
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.detail.status ===
                                                      "Change Unit" && _vm.view
                                                      ? "Update"
                                                      : "Change Unit"
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.isAcceptanceOfOrder &&
                                      !_vm.isChangeUnit &&
                                      !_vm.isCancelled &&
                                      _vm.$can(
                                        "change-location",
                                        "internal-contract"
                                      )
                                        ? _c(
                                            "a-button",
                                            {
                                              attrs: { type: "primary" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.onSubmit(
                                                    "Change Location"
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.detail.status ===
                                                      "Change Location" &&
                                                      _vm.view
                                                      ? "Update"
                                                      : "Change Location"
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      (_vm.isEnded ||
                                        _vm.isRevised ||
                                        _vm.isNeedApproval ||
                                        _vm.isRejected ||
                                        _vm.isApproved ||
                                        _vm.isPartialEnded ||
                                        _vm.isCancelled) &&
                                      _vm.$can("read", "internal-contract")
                                        ? _c(
                                            "a-button",
                                            {
                                              attrs: {
                                                loading: _vm.loading.submit,
                                                type: "primary"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.onSubmit("History")
                                                }
                                              }
                                            },
                                            [_vm._v(" History ")]
                                          )
                                        : _vm._e(),
                                      (_vm.isApproved || _vm.isPartialEnded) &&
                                      _vm.isAllBastPosted &&
                                      _vm.$can(
                                        "end-contract",
                                        "internal-contract"
                                      )
                                        ? [
                                            _c(
                                              "a-popconfirm",
                                              {
                                                attrs: {
                                                  title: _vm.$t(
                                                    "pop_confirmation"
                                                  ),
                                                  "ok-text": _vm.$t("lbl_yes"),
                                                  "cancel-text": _vm.$t(
                                                    "lbl_no"
                                                  )
                                                },
                                                on: {
                                                  confirm: function($event) {
                                                    return _vm.onSubmit("Ended")
                                                  }
                                                }
                                              },
                                              [
                                                _c(
                                                  "a-button",
                                                  {
                                                    attrs: {
                                                      type: "danger",
                                                      loading:
                                                        _vm.loading.submit
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "lbl_end_contract"
                                                          )
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        : _vm._e(),
                                      (_vm.isApproved || _vm.isPartialEnded) &&
                                      _vm.rentType !== "Day" &&
                                      _vm.$can("update", "internal-contract")
                                        ? _c(
                                            "a-button",
                                            {
                                              attrs: {
                                                loading: _vm.loading.submit,
                                                type: "primary"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.onSubmit("Extend")
                                                }
                                              }
                                            },
                                            [_vm._v(" Extend ")]
                                          )
                                        : _vm._e(),
                                      _vm.isNeedApproval &&
                                      _vm.$can("update", "internal-contract")
                                        ? _c(
                                            "a-button",
                                            {
                                              attrs: {
                                                loading: _vm.loading.submit,
                                                type: "primary"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.onSubmit(
                                                    "approve/rejected"
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.isAcceptanceOfOrder
                                                      ? "Approve"
                                                      : "Approve/Rejected"
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.isRevised &&
                                      _vm.$can("update", "internal-contract")
                                        ? _c(
                                            "a-button",
                                            {
                                              attrs: {
                                                loading: _vm.loading.submit,
                                                type: "primary"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.onSubmit("Revised")
                                                }
                                              }
                                            },
                                            [_vm._v(" Submit ")]
                                          )
                                        : _vm._e()
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.viewHistory
                            ? _c(
                                "a-col",
                                {
                                  staticStyle: { "margin-top": "10px" },
                                  attrs: { span: 24, align: "end" }
                                },
                                [
                                  _c(
                                    "a-space",
                                    [
                                      _c(
                                        "a-button",
                                        { on: { click: _vm.handleBack } },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("lbl_back")) +
                                              " "
                                          )
                                        ]
                                      ),
                                      (_vm.isEnded ||
                                        _vm.isRevised ||
                                        _vm.isNeedApproval ||
                                        _vm.isRejected ||
                                        _vm.isApproved ||
                                        _vm.isPartialEnded ||
                                        _vm.isCancelled) &&
                                      _vm.$can("read", "internal-contract")
                                        ? _c(
                                            "a-button",
                                            {
                                              attrs: {
                                                loading: _vm.loading.submit,
                                                type: "primary"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.onSubmit("History")
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t("lbl_history")
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "Modal",
            {
              attrs: {
                centered: false,
                dialogstyle: { top: "20px" },
                maskClosable: false,
                width: 520,
                title: "History",
                showmodal: _vm.modalHistoryOpen,
                idmodal: "modal2"
              }
            },
            [
              _c(
                "div",
                { attrs: { slot: "contentmodal" }, slot: "contentmodal" },
                [
                  _c(
                    "a-timeline",
                    { attrs: { mode: "alternate" } },
                    _vm._l(_vm.dataHistoryTimeLine, function(data, index) {
                      return _c(
                        "a-timeline-item",
                        {
                          key: index,
                          attrs: { color: "green" },
                          on: {
                            click: function($event) {
                              return _vm.handleViewHistory(index)
                            }
                          }
                        },
                        [
                          _c("a-icon", {
                            staticStyle: { "font-size": "16px" },
                            attrs: { slot: "dot", type: "clock-circle-o" },
                            slot: "dot"
                          }),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                cursor: "pointer",
                                display: "flex",
                                "flex-direction": "column"
                              }
                            },
                            [
                              _vm._v(" " + _vm._s(data.historyStatus) + " "),
                              _c("br"),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm
                                      .moment(data.createdDate)
                                      .format("DD-MMM-YYYY HH:mm:ss")
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { attrs: { slot: "footermodal" }, slot: "footermodal" },
                [
                  _c(
                    "a-button",
                    {
                      key: "back",
                      attrs: { type: "danger" },
                      on: { click: _vm.handleCancelModalHistory }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_cancel")) + " ")]
                  ),
                  _c(
                    "a-button",
                    {
                      key: "submit",
                      attrs: { type: "primary", "html-type": "submit" },
                      on: {
                        click: function($event) {
                          return _vm.handleViewHistory(0)
                        }
                      }
                    },
                    [_vm._v(" View Original ")]
                  )
                ],
                1
              )
            ]
          ),
          _c(
            "Modal",
            {
              attrs: {
                centered: false,
                dialogstyle: { top: "20px" },
                maskClosable: false,
                width: 520,
                title:
                  _vm.modeModal === "approve/rejected"
                    ? "Approve / Rejected"
                    : _vm.modeModal,
                showmodal: _vm.modalOpen,
                idmodal: "modal1"
              }
            },
            [
              _vm.modeModal === "Extend"
                ? _c(
                    "div",
                    { attrs: { slot: "contentmodal" }, slot: "contentmodal" },
                    [
                      _c(
                        "a-form",
                        _vm._b(
                          {
                            attrs: { form: _vm.formModal, layout: "vertical" },
                            on: {
                              submit: function($event) {
                                $event.preventDefault()
                                return _vm.onSubmit.apply(null, arguments)
                              }
                            }
                          },
                          "a-form",
                          _vm.formItemLayout,
                          false
                        ),
                        [
                          _c(
                            "a-form-model-item",
                            {
                              attrs: {
                                label: _vm.$t(
                                  _vm.formRulesModal.rentPeriod.label
                                )
                              }
                            },
                            [
                              _c("a-input-number", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value:
                                      _vm.formRulesModal.rentPeriod.decorator,
                                    expression:
                                      "formRulesModal.rentPeriod.decorator"
                                  }
                                ],
                                staticClass: "w-100",
                                attrs: {
                                  min: 0,
                                  name: _vm.formRulesModal.rentPeriod.name,
                                  placeholder:
                                    _vm.formRulesModal.rentPeriod.placeholder,
                                  parser: _vm.InputNumberOnly
                                },
                                on: {
                                  change: function(value) {
                                    return _vm.handleChangeRentPeriod(
                                      value,
                                      true
                                    )
                                  }
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "a-form-model-item",
                            {
                              attrs: {
                                label: _vm.$t(_vm.formRulesModal.notes.label)
                              }
                            },
                            [
                              _c("a-textarea", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value: _vm.formRulesModal.notes.decorator,
                                    expression: "formRulesModal.notes.decorator"
                                  }
                                ],
                                attrs: {
                                  name: _vm.formRulesModal.notes.name,
                                  placeholder:
                                    _vm.formRulesModal.notes.placeholder,
                                  "auto-size": { minRows: 3, maxRows: 5 }
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.modeModal === "approve/rejected" || _vm.modeModal === "Ended"
                ? _c(
                    "div",
                    { attrs: { slot: "contentmodal" }, slot: "contentmodal" },
                    [
                      _c(
                        "a-form",
                        _vm._b(
                          {
                            attrs: { form: _vm.formModal, layout: "vertical" },
                            on: {
                              submit: function($event) {
                                $event.preventDefault()
                                return _vm.onSubmit.apply(null, arguments)
                              }
                            }
                          },
                          "a-form",
                          _vm.formItemLayout,
                          false
                        ),
                        [
                          _c(
                            "a-form-model-item",
                            {
                              attrs: {
                                label: _vm.$t(_vm.formRulesModal.notes.label)
                              }
                            },
                            [
                              _c("a-textarea", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value: _vm.formRulesModal.notes.decorator,
                                    expression: "formRulesModal.notes.decorator"
                                  }
                                ],
                                attrs: {
                                  name: _vm.formRulesModal.notes.name,
                                  placeholder:
                                    _vm.formRulesModal.notes.placeholder,
                                  "auto-size": { minRows: 3, maxRows: 5 }
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                { attrs: { slot: "footermodal" }, slot: "footermodal" },
                [
                  _c(
                    "a-button",
                    {
                      key: "back",
                      attrs: { type: "danger" },
                      on: { click: _vm.handleCancelModal }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_cancel")) + " ")]
                  ),
                  _vm.modeModal === "Ended"
                    ? _c(
                        "a-button",
                        {
                          key: "submit",
                          attrs: {
                            type: "primary",
                            loading: _vm.loading.submitFormModal,
                            "html-type": "submit"
                          },
                          on: {
                            click: function($event) {
                              return _vm.onSubmit(_vm.modeModal, "Ended")
                            }
                          }
                        },
                        [_vm._v(" Submit ")]
                      )
                    : _vm._e(),
                  _vm.modeModal === "Extend"
                    ? _c(
                        "a-button",
                        {
                          key: "submit",
                          attrs: {
                            type: "primary",
                            loading: _vm.loading.submitFormModal,
                            "html-type": "submit"
                          },
                          on: {
                            click: function($event) {
                              return _vm.onSubmit(_vm.modeModal, "Extended")
                            }
                          }
                        },
                        [_vm._v(" Submit ")]
                      )
                    : _vm._e(),
                  _vm.modeModal === "approve/rejected" &&
                  !_vm.isAcceptanceOfOrder
                    ? _c(
                        "a-button",
                        {
                          key: "submit1",
                          attrs: {
                            type: "danger",
                            loading: _vm.loading.submitFormModal,
                            "html-type": "submit"
                          },
                          on: {
                            click: function($event) {
                              return _vm.onSubmit(_vm.modeModal, "Rejected")
                            }
                          }
                        },
                        [_vm._v(" Reject ")]
                      )
                    : _vm._e(),
                  _vm.modeModal === "approve/rejected"
                    ? _c(
                        "a-button",
                        {
                          key: "submit2",
                          attrs: {
                            type: "primary",
                            loading: _vm.loading.submitFormModal,
                            "html-type": "submit"
                          },
                          on: {
                            click: function($event) {
                              return _vm.onSubmit(_vm.modeModal, "Approved")
                            }
                          }
                        },
                        [_vm._v(" Approve ")]
                      )
                    : _vm._e()
                ],
                1
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }