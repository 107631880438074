var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { title: _vm.$t("lbl_bank_transfer") } },
    [
      _c(
        "a-form",
        {
          attrs: {
            layout: "horizontal",
            "label-col": { span: 8 },
            "wrapper-col": { span: 14 },
            "label-align": "left",
            form: _vm.form
          }
        },
        [
          _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _vm.mode != "create"
                    ? _c(
                        "a-form-item",
                        { attrs: { label: _vm.$t("lbl_transfer_no") } },
                        [
                          _c("a-input", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: _vm.formRules.transferNo.decorator,
                                expression: "formRules.transferNo.decorator"
                              }
                            ],
                            attrs: {
                              name: _vm.$t("lbl_transfer_no"),
                              placeholder: _vm.$t(
                                "lbl_transfer_no_placeholder"
                              ),
                              autocomplete: "off",
                              disabled: true
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "a-form-item",
                    { attrs: { label: _vm.$t("lbl_branch") } },
                    [
                      _c(
                        "a-select",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: _vm.formRules.branch.decorator,
                              expression: "formRules.branch.decorator"
                            }
                          ],
                          attrs: {
                            name: _vm.$t("lbl_branch"),
                            placeholder: _vm.$t("lbl_branch_placeholder"),
                            showSearch: true,
                            "filter-option": false,
                            loading: _vm.loading.branch,
                            disabled: _vm.disabled.branch,
                            allowClear: true
                          },
                          on: {
                            search: function(value) {
                              return _vm.searchDropdown(value, "branch")
                            }
                          }
                        },
                        _vm._l(_vm.dataBranch, function(data, index) {
                          return _c(
                            "a-select-option",
                            { key: index, attrs: { value: data.id } },
                            [
                              _c(
                                "a-tooltip",
                                [
                                  _c("template", { slot: "title" }, [
                                    _vm._v(" " + _vm._s(data.name) + " ")
                                  ]),
                                  _vm._v(" " + _vm._s(data.name) + " ")
                                ],
                                2
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { label: _vm.$t("lbl_transfer_date") } },
                    [
                      _c("a-date-picker", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: _vm.formRules.transferDate.decorator,
                            expression: "formRules.transferDate.decorator"
                          }
                        ],
                        staticClass: "w-100",
                        attrs: {
                          name: _vm.$t("lbl_transfer_date"),
                          placeholder: _vm.$t("lbl_transfer_date_placeholder"),
                          format: _vm.DEFAULT_DATE_FORMAT,
                          "disabled-date": _vm.useDisabledFromTomorrow,
                          disabled: _vm.disabled.transferDate
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { label: _vm.$t("lbl_currency") } },
                    [
                      _c(
                        "a-select",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: _vm.formRules.currency.decorator,
                              expression: "formRules.currency.decorator"
                            }
                          ],
                          attrs: {
                            name: _vm.$t("lbl_currency"),
                            placeholder: _vm.$t("lbl_currency_placeholder"),
                            showSearch: true,
                            "filter-option": false,
                            loading: _vm.loading.currency,
                            allowClear: true,
                            disabled: _vm.disabled.currency
                          },
                          on: {
                            search: function(value) {
                              return _vm.searchDropdown(value, "currency")
                            },
                            change: function(value) {
                              return _vm.changeDropdown(value, "currency")
                            }
                          }
                        },
                        _vm._l(_vm.dataCurrency, function(data, index) {
                          return _c(
                            "a-select-option",
                            { key: index, attrs: { value: data.currencyCode } },
                            [
                              _c(
                                "a-tooltip",
                                [
                                  _c("template", { slot: "title" }, [
                                    _vm._v(
                                      " " + _vm._s(data.currencyCode) + " "
                                    )
                                  ]),
                                  _vm._v(" " + _vm._s(data.currencyCode) + " ")
                                ],
                                2
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { label: _vm.$t("lbl_rate") } },
                    [
                      _c("a-input-number", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: _vm.formRules.rate.decorator,
                            expression: "formRules.rate.decorator"
                          }
                        ],
                        staticClass: "w-100",
                        attrs: {
                          placeholder: _vm.$t("lbl_rate_placeholder"),
                          name: _vm.$t("lbl_rate"),
                          disabled: _vm.disabled.rate,
                          parser: _vm.reverseFormatNumber,
                          formatter: _vm.formatterNumber,
                          min: 0,
                          precision: _vm.storeBaseDecimalPlace
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { label: _vm.$t("lbl_transfer_amount") } },
                    [
                      _c("a-input-number", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: _vm.formRules.transferAmount.decorator,
                            expression: "formRules.transferAmount.decorator"
                          }
                        ],
                        staticClass: "w-100",
                        attrs: {
                          name: _vm.$t("lbl_transfer_amount"),
                          placeholder: _vm.$t(
                            "lbl_transfer_amount_placeholder"
                          ),
                          disabled: _vm.disabled.transferAmount,
                          parser: _vm.reverseFormatNumber,
                          formatter: _vm.formatterNumber,
                          min: 0,
                          precision: _vm.storeBaseDecimalPlace
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-item",
                    { attrs: { label: _vm.$t("lbl_description") } },
                    [
                      _c("a-textarea", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: _vm.formRules.description.decorator,
                            expression: "formRules.description.decorator"
                          }
                        ],
                        attrs: {
                          name: _vm.$t("lbl_description"),
                          placeholder: _vm.$t("lbl_description_placeholder"),
                          rows: 4,
                          disabled: _vm.disabled.description
                        }
                      }),
                      _c("character-length", {
                        attrs: { value: _vm.form.getFieldValue("description") }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    {
                      staticStyle: { "margin-top": "1rem" },
                      attrs: {
                        label: _vm.$t("lbl_attachment_file"),
                        help:
                          "Maximum file size is 5MB and accepted file extension is PDF, JPG, PNG, XLSX"
                      }
                    },
                    [
                      _c(
                        "a-upload",
                        {
                          attrs: {
                            name: "file",
                            multiple: false,
                            method: "post",
                            "file-list": _vm.fileList,
                            customRequest: _vm.customRequest,
                            "before-upload": _vm.beforeUpload,
                            accept: ".pdf, .png, .jpg, .xlsx"
                          },
                          on: { change: _vm.handleChange }
                        },
                        [
                          _c(
                            "a-button",
                            {
                              attrs: {
                                disabled: _vm.disabled.file,
                                icon: "upload"
                              }
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("lbl_upload")) + " ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  !!_vm.journalNumber.id
                    ? _c(
                        "a-form-item",
                        { attrs: { label: _vm.$t("lbl_journal_number") } },
                        [
                          _c(
                            "router-link",
                            {
                              attrs: {
                                to: {
                                  name: "generaljournal.detail",
                                  params: { id: _vm.journalNumber.id }
                                }
                              }
                            },
                            [
                              _c("a-button", { attrs: { type: "link" } }, [
                                _vm._v(
                                  " " + _vm._s(_vm.journalNumber.name) + " "
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.mode !== "create"
                    ? _c(
                        "a-form-item",
                        { attrs: { label: _vm.$t("lbl_status") } },
                        [
                          _c("a-input", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: _vm.formRules.status.decorator,
                                expression: "formRules.status.decorator"
                              }
                            ],
                            attrs: {
                              name: _vm.$t("lbl_status"),
                              placeholder: _vm.$t("lbl_status_placeholder"),
                              autocomplete: "off",
                              disabled: true
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c("a-divider"),
          _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-item",
                    { attrs: { label: _vm.$t("lbl_bank_source") } },
                    [
                      _c(
                        "a-select",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: _vm.formRules.bankSource.decorator,
                              expression: "formRules.bankSource.decorator"
                            }
                          ],
                          attrs: {
                            name: _vm.$t("lbl_bank_source"),
                            placeholder: _vm.$t("lbl_bank_source_placeholder"),
                            showSearch: true,
                            "filter-option": false,
                            loading: _vm.loading.bankSource,
                            allowClear: true,
                            disabled: _vm.disabled.bankSource
                          },
                          on: {
                            search: function(value) {
                              return _vm.searchDropdown(value, "bankSource")
                            },
                            change: function(value) {
                              return _vm.changeDropdown(value, "bankSource")
                            }
                          }
                        },
                        _vm._l(_vm.dataBankSource, function(data, index) {
                          return _c(
                            "a-select-option",
                            {
                              key: index,
                              attrs: {
                                value: data.id,
                                disabled:
                                  data.id ===
                                  _vm.form.getFieldValue("bankDestination")
                              }
                            },
                            [
                              _c(
                                "a-tooltip",
                                [
                                  _c("template", { slot: "title" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(data.bankName) +
                                        " - " +
                                        _vm._s(data.bankAccNumber) +
                                        " "
                                    )
                                  ]),
                                  _vm._v(
                                    " " +
                                      _vm._s(data.bankName) +
                                      " - " +
                                      _vm._s(data.bankAccNumber) +
                                      " "
                                  )
                                ],
                                2
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c("h3", [
                    _vm._v(" " + _vm._s(_vm.$t("lbl_bank_details")) + " ")
                  ]),
                  _c(
                    "a-form-item",
                    { attrs: { label: _vm.$t("lbl_bank_account_name") } },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: _vm.formRules.bankAccountSource.decorator,
                            expression: "formRules.bankAccountSource.decorator"
                          }
                        ],
                        attrs: {
                          name: _vm.$t("lbl_bank_account_name"),
                          placeholder: _vm.$t(
                            "lbl_bank_account_name_placeholder"
                          ),
                          autocomplete: "off",
                          disabled: true
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { label: _vm.$t("lbl_bank_account_number") } },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value:
                              _vm.formRules.bankAccountNumberSource.decorator,
                            expression:
                              "formRules.bankAccountNumberSource.decorator"
                          }
                        ],
                        attrs: {
                          name: _vm.$t("lbl_bank_account_number"),
                          placeholder: _vm.$t(
                            "lbl_bank_account_number_placeholder"
                          ),
                          autocomplete: "off",
                          disabled: true
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { label: _vm.$t("lbl_bank_name") } },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: _vm.formRules.bankNameSource.decorator,
                            expression: "formRules.bankNameSource.decorator"
                          }
                        ],
                        attrs: {
                          name: _vm.$t("lbl_bank_name"),
                          placeholder: _vm.$t("lbl_bank_name_placeholder"),
                          autocomplete: "off",
                          disabled: true
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { label: _vm.$t("lbl_bank_branch_name") } },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: _vm.formRules.bankBranchNameSource.decorator,
                            expression:
                              "formRules.bankBranchNameSource.decorator"
                          }
                        ],
                        attrs: {
                          name: _vm.$t("lbl_bank_branch_name"),
                          placeholder: _vm.$t(
                            "lbl_bank_branch_name_placeholder"
                          ),
                          autocomplete: "off",
                          disabled: true
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-item",
                    { attrs: { label: _vm.$t("lbl_bank_destination") } },
                    [
                      _c(
                        "a-select",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: _vm.formRules.bankDestination.decorator,
                              expression: "formRules.bankDestination.decorator"
                            }
                          ],
                          attrs: {
                            name: _vm.$t("lbl_bank_destination"),
                            placeholder: _vm.$t(
                              "lbl_bank_destination_placeholder"
                            ),
                            showSearch: true,
                            "filter-option": false,
                            loading: _vm.loading.bankDestination,
                            allowClear: true,
                            disabled: _vm.disabled.bankDestination
                          },
                          on: {
                            search: function(value) {
                              return _vm.searchDropdown(
                                value,
                                "bankDestination"
                              )
                            },
                            change: function(value) {
                              return _vm.changeDropdown(
                                value,
                                "bankDestination"
                              )
                            }
                          }
                        },
                        _vm._l(_vm.dataBankDestination, function(data, index) {
                          return _c(
                            "a-select-option",
                            {
                              key: index,
                              attrs: {
                                value: data.id,
                                disabled:
                                  data.id ===
                                  _vm.form.getFieldValue("bankSource")
                              }
                            },
                            [
                              _c(
                                "a-tooltip",
                                [
                                  _c("template", { slot: "title" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(data.bankName) +
                                        " - " +
                                        _vm._s(data.bankAccNumber) +
                                        " "
                                    )
                                  ]),
                                  _vm._v(
                                    " " +
                                      _vm._s(data.bankName) +
                                      " - " +
                                      _vm._s(data.bankAccNumber) +
                                      " "
                                  )
                                ],
                                2
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c("h3", [
                    _vm._v(" " + _vm._s(_vm.$t("lbl_bank_details")) + " ")
                  ]),
                  _c(
                    "a-form-item",
                    { attrs: { label: _vm.$t("lbl_bank_account_name") } },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value:
                              _vm.formRules.bankAccountDestination.decorator,
                            expression:
                              "formRules.bankAccountDestination.decorator"
                          }
                        ],
                        attrs: {
                          name: _vm.$t("lbl_bank_account_name"),
                          placeholder: _vm.$t(
                            "lbl_bank_account_name_placeholder"
                          ),
                          autocomplete: "off",
                          disabled: true
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { label: _vm.$t("lbl_bank_account_number") } },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value:
                              _vm.formRules.bankAccountNumberDestination
                                .decorator,
                            expression:
                              "formRules.bankAccountNumberDestination.decorator"
                          }
                        ],
                        attrs: {
                          name: _vm.$t("lbl_bank_account_number"),
                          placeholder: _vm.$t(
                            "lbl_bank_account_number_placeholder"
                          ),
                          autocomplete: "off",
                          disabled: true
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { label: _vm.$t("lbl_bank_name") } },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: _vm.formRules.bankNameDestination.decorator,
                            expression:
                              "formRules.bankNameDestination.decorator"
                          }
                        ],
                        attrs: {
                          name: _vm.$t("lbl_bank_name"),
                          placeholder: _vm.$t("lbl_bank_name_placeholder"),
                          autocomplete: "off",
                          disabled: true
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { label: _vm.$t("lbl_bank_branch_name") } },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value:
                              _vm.formRules.bankBranchNameDestination.decorator,
                            expression:
                              "formRules.bankBranchNameDestination.decorator"
                          }
                        ],
                        attrs: {
                          name: _vm.$t("lbl_bank_branch_name"),
                          placeholder: _vm.$t(
                            "lbl_bank_branch_name_placeholder"
                          ),
                          autocomplete: "off",
                          disabled: true
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("a-divider"),
          _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { span: 24, align: "end" } },
                [
                  _c(
                    "a-space",
                    [
                      _c("a-button", { on: { click: _vm.back } }, [
                        _vm._v(" " + _vm._s(_vm.$t("lbl_back")) + " ")
                      ]),
                      _vm.mode !== "create"
                        ? _c(
                            "a-button",
                            {
                              attrs: {
                                type: "primary",
                                loading: _vm.loading.print
                              },
                              on: { click: _vm.handlePrint }
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("lbl_print")) + " ")]
                          )
                        : _vm._e(),
                      _vm.show.saveAsDraft &&
                      _vm.$can("create", "cm-bank-transfer")
                        ? _c(
                            "a-button",
                            {
                              attrs: { loading: _vm.loading.saveAsDraft },
                              on: {
                                click: function($event) {
                                  return _vm.onCLick("saveAsDraft")
                                }
                              }
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("lbl_save_as_draft")) + " "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm.show.update && _vm.$can("update", "cm-bank-transfer")
                        ? _c(
                            "a-button",
                            {
                              attrs: {
                                type: "primary",
                                loading: _vm.loading.update
                              },
                              on: {
                                click: function($event) {
                                  return _vm.onCLick("update")
                                }
                              }
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("lbl_update")) + " ")]
                          )
                        : _vm._e(),
                      _vm.show.submit && _vm.$can("create", "cm-bank-transfer")
                        ? _c(
                            "a-button",
                            {
                              attrs: {
                                type: "primary",
                                loading: _vm.loading.submit
                              },
                              on: {
                                click: function($event) {
                                  return _vm.onCLick("submit")
                                }
                              }
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("lbl_submit")) + " ")]
                          )
                        : _vm._e(),
                      _vm.show.approve && _vm.$can("update", "cm-bank-transfer")
                        ? _c(
                            "a-button",
                            {
                              attrs: {
                                type: "primary",
                                loading: _vm.loading.approve
                              },
                              on: {
                                click: function($event) {
                                  return _vm.onCLick("approve")
                                }
                              }
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("lbl_approve")) + " ")]
                          )
                        : _vm._e(),
                      _vm.show.reject && _vm.$can("update", "cm-bank-transfer")
                        ? _c(
                            "a-button",
                            {
                              attrs: {
                                type: "danger",
                                loading: _vm.loading.reject
                              },
                              on: {
                                click: function($event) {
                                  return _vm.onCLick("reject")
                                }
                              }
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("lbl_reject")) + " ")]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }